// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */

:root {
  --bg-0: #000000;
  --bg-1: #1f1f1f;
  --bg-2: #383838;
  --bg-3: #3d3d3d;
  --bg-4: #3e3e42;
  --bg-5: #424242;
  --bg-6: #626262;

  --border-1: #3d3d3d;
  --border-2: #686868;

  --text-0: #000000;
  --text-1: #333333;
  --text-2: #777777;
  --text-3: #aaaaaa;
  --text-4: #cccccc;
  --text-5: #ffffff;

  --bs-body-color: var(--text-5);
  --bs-body-bg: var(--bg-2);

  --padding-1: 8px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--bg-2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--bg-6);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--bg-6);
}

body {
  background-color: var(--bg-0);
}